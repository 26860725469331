import {
	browserLocalPersistence,
	browserSessionPersistence,
	indexedDBLocalPersistence,
	initializeAuth,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import * as React from 'react';
import { SecondaryButton } from './Buttons';

const app = firebase.initializeApp({
	apiKey: 'AIzaSyAc3l1-sfLlz0pLrBnqrMv5ajk8gGkJUbA',
	appId: '1:1021061313029:web:c64f8baf3b7dead67ae26c',
	authDomain: 'auth.googlefeud.com',
	measurementId: 'G-XTQ5L45WJP',
	messagingSenderId: '1021061313029',
	projectId: 'gfeud2022',
	storageBucket: 'gfeud2022.appspot.com',
});
const auth = initializeAuth(app, {
	persistence: [
		indexedDBLocalPersistence,
		browserLocalPersistence,
		browserSessionPersistence,
	],
});

const SignInButton = () => {
	const [user, setUser] = React.useState(auth.currentUser);
	React.useEffect(() => {
		/*
		const signInAnonymouslyAsync = async () => {
			const {user} = await signInAnonymously(auth);
			setUser(user);
		};
		*/
		const unregisterAuthObserver = auth.onAuthStateChanged((currentUser) => {
			setUser(currentUser);
			/*
			if (currentUser === null) {
				signInAnonymouslyAsync();
			}
			*/
		});
		return () => unregisterAuthObserver();
	}, []);

	return user ? (
		<img
			alt={user.displayName ? `Profile - ${user.displayName}` : 'Profile'}
			src={user.photoURL ?? '../images/user.png'}
			style={{
				height: 'var(--double)',
				objectFit: 'scale-down',
				width: 'var(--double)',
			}}
		/>
	) : (
		<SecondaryButton signin text="Sign In" />
	);
};
export default SignInButton;
